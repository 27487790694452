<template>
  <div>
    <div
      v-if="Array.isArray(data.mediaBlocks) && data.mediaBlocks.length"
      class="mb-6 h-14 w-14 lg:mb-10 lg:h-20 lg:w-20"
    >
      <Lottie :data="data.mediaBlocks[0]" />
    </div>
    <div v-else-if="data.image" class="mb-6 lg:mb-10">
      <Image
        v-if="data.image"
        loading="lazy"
        :responsive="false"
        class="object-cover"
        :class="
          data.image.url.includes('.svg')
            ? 'h-14 w-14 lg:h-20 lg:w-20'
            : 'h-40 h-full w-40 w-full lg:h-60 lg:w-60'
        "
        :sizes="data.image.responsiveImage?.sizes"
        :src="data.image.responsiveImage ? data.image.responsiveImage?.src : data.image.url"
        :srcset="data.image.responsiveImage ? data.image.responsiveImage?.webpSrcSet : undefined"
        :width="data.image.responsiveImage ? data.image.responsiveImage.width : data.image.width"
        :height="data.image.responsiveImage ? data.image.responsiveImage.height : data.image.height"
        :alt="data.image.alt ? data.image.alt : ''"
      />
    </div>
    <div v-if="data.title" class="mb-3 text-2xl lg:mb-5 lg:text-3xl">{{ data.title }}</div>
    <div v-if="data.body" class="mb-4 text-base lg:mb-6 lg:text-lg" v-html="data.body" />
    <div v-if="data.link">
      <NuxtLink
        :to="localePathByType(data.link?.__typename as string, data.link?.slug as string)"
        class="link"
        >{{ $t('articles.more') }}</NuxtLink
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import type { FeatureRecord } from '~/types/generated'

const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Object as PropType<FeatureRecord>,
    default: () => {}
  }
})
</script>
